import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '众康联门店后台',
    redirect: '/login',
  },
  {
    path: '/login',
    // name: '登录',
    meta: { title: "众康联门店后台", requireAuth: true },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/home',
    name: '概览',
    icon: "icon-shouye",
    meta: { title: "概览", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/home",
        // name: "首页",
        icon: "icon-shouye",
        meta: { title: "概览", requireAuth: true },
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/manage',
    name: '管理员',
    icon: "icon-guanliyuan1",
    meta: { title: "管理员", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/manage/adminList",
        name: "管理员列表",
        icon: "icon-guanliyuan",
        meta: { title: "管理员列表", requireAuth: true },
        component: () => import('@/views/manage/adminList/adminList.vue')
      },
      {
        path: "/manage/auth",
        name: "角色管理",
        icon: "icon-quanxianguanli",
        meta: { title: "角色管理", requireAuth: true },
        component: () => import('@/views/manage/auth/auth.vue')
      },
      {
        path: "/manage/routeList",
        name: "路由列表",
        icon: "icon-guanliyuan",
        meta: { title: "路由列表", requireAuth: true },
        component: () => import('@/views/manage/routeList/routeList.vue')
      }
    ]
  },
  {
    path: '/user',
    name: '用户管理',
    icon: "icon-kehu",
    meta: { title: "用户管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/user",
        name: "用户列表",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "用户列表", requireAuth: true },
        component: () => import('@/views/user/index.vue'),
      },
      {
        path: "/user/userDetails",
        name: "用户详情",
        icon: "icon-shouye",
        meta: { title: "用户详情", requireAuth: true },
        component: () => import('@/views/user/userDetails/index.vue'),
      }
    ]
  },
  {
    path: '/commodity',
    name: '商品管理',
    icon: "icon-shezhi",
    meta: { title: "商品管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/commodity/self",
        name: "平台供货库",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "平台供货库", requireAuth: true },
        component: () => import('@/views/commodity/selfIndex.vue')
      },
      {
        path: "/commodity/selfDetails",
        name: "查看",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "查看", requireAuth: true },
        component: () => import('@/views/commodity/selfDetails/selfDetails.vue') 
      },
      {
        path: "/commodity/cattle",
        name: "门店自营商品",
        icon: "icon-shangpinduibi",
        meta: { title: "门店自营商品", requireAuth: true },
        component: () => import('@/views/commodity/cattleIndex.vue')
      },
      {
        path: "/commodity/addCommodity",
        name: "新增商品",
        icon: "icon-shouye",
        meta: { title: "新增商品", requireAuth: true },
        component: () => import('@/views/commodity/addCommodity/index.vue')
      },
      {
        path: "/commodity/auditList",
        name: "审核列表",
        icon: "icon-shouye",
        meta: { title: "审核列表", requireAuth: true },
        component: () => import('@/views/commodity/auditList.vue')
      },
      {
        path: "/commodity/shopClass",
        name: "商品分类",
        icon: "icon-shouye",
        meta: { title: "商品分类", requireAuth: true },
        component: () => import('@/views/commodity/shopClass.vue')
      },
      {
        path: "/commodity/paveGoods",
        name: "铺货",
        icon: "icon-shouye",
        meta: { title: "铺货", requireAuth: true },
        component: () => import('@/views/commodity/paveGoods.vue')
      }
    ]
  },
  {
    path: '/order',
    name: '订单管理',
    icon: "icon-shezhi",
    meta: { title: "订单管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/order/providedList",
        name: "供货订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "供货订单", requireAuth: true },
        component: () => import('@/views/order/providedList.vue')
      },
      {
        path: "/order/storeOrder",
        name: "门店订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "门店订单", requireAuth: true },
        component: () => import('@/views/order/storeOrder.vue')
      },
      {
        path: "/order/details/providedOrderDetails",
        name: "供货订单详情",
        icon: "icon-shouye",
        meta: { title: "供货订单详情", requireAuth: true },
        component: () => import('@/views/order/details/providedOrderDetails.vue')
      },
      {
        path: "/order/details/storeOrderDetails",
        name: "门店订单详情",
        icon: "icon-shouye",
        meta: { title: "门店订单详情", requireAuth: true },
        component: () => import('@/views/order/details/storeOrderDetails.vue')
      }
    ]
  },
  {
    path: '/service',
    name: '服务管理',
    icon: "icon-shezhi",
    meta: { title: "服务管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/service/virtualGoods",
        name: "虚拟商品",
        icon: "icon-dingdanliebiao",
        meta: { title: "虚拟商品", requireAuth: true },
        component: () => import('@/views/service/virtualGoods.vue')
      },
      {
        path: "/service/subOrder/addVirtualGoods",
        name: "新增虚拟商品",
        icon: "icon-dingdanliebiao",
        meta: { title: "新增虚拟商品", requireAuth: true },
        component: () => import('@/views/service/subOrder/addVirtualGoods.vue')
      },
      {
        path: "/service/billing",
        name: "开单卡片",
        icon: "icon-dingdanliebiao",
        meta: { title: "开单卡片", requireAuth: true },
        component: () => import('@/views/service/billing.vue')
      },
      {
        path: "/service/subOrder/billingDetails",
        name: "开单商品",
        icon: "icon-dingdanliebiao",
        meta: { title: "开单商品", requireAuth: true },
        component: () => import('@/views/service/subOrder/billingDetails.vue')
      },
      {
        path: "/service/subOrder/billingPay",
        name: "开单收银",
        icon: "icon-dingdanliebiao",
        meta: { title: "开单收银", requireAuth: true },
        component: () => import('@/views/service/subOrder/billingPay.vue')
      },
      {
        path: "/service/pushWriteOff",
        name: "输码核销",
        icon: "icon-dingdanliebiao",
        meta: { title: "输码核销", requireAuth: true },
        component: () => import('@/views/service/pushWriteOff.vue')
      },
      {
        path: "/service/writeOffRecord",
        name: "核销记录",
        icon: "icon-dingdanliebiao",
        meta: { title: "核销记录", requireAuth: true },
        component: () => import('@/views/service/writeOffRecord.vue')
      },
      {
        path: "/service/craftsmanList",
        name: "手艺人列表",
        icon: "icon-dingdanliebiao",
        meta: { title: "手艺人列表", requireAuth: true },
        component: () => import('@/views/service/craftsmanList.vue')
      },
      {
        path: "/service/subOrder/addCraftsman",
        name: "添加/编辑手艺人",
        icon: "icon-dingdanliebiao",
        meta: { title: "添加/编辑手艺人", requireAuth: true },
        component: () => import('@/views/service/subOrder/addCraftsman.vue')
      },
      {
        path: "/service/carftsmanSchedule",
        name: "手艺人排期",
        icon: "icon-dingdanliebiao",
        meta: { title: "手艺人排期", requireAuth: true },
        component: () => import('@/views/service/carftsmanSchedule.vue')
      },
      {
        path: "/service/shiftSetting",
        name: "班次设置",
        icon: "icon-dingdanliebiao", 
        meta: { title: "班次设置", requireAuth: true },
        component: () => import('@/views/service/shiftSetting.vue')
      },
      {
        path: "/service/subOrder/shiftSettingDetails",
        name: "添加/编辑班次设置",
        icon: "icon-dingdanliebiao", 
        meta: { title: "添加/编辑班次设置", requireAuth: true },
        component: () => import('@/views/service/subOrder/shiftSettingDetails.vue')
      },
      {
        path: "/service/makeList",
        name: "预约列表",
        icon: "icon-dingdanliebiao", 
        meta: { title: "预约列表", requireAuth: true },
        component: () => import('@/views/service/makeList.vue')
      },
    ]
  },
  {
    path: '/after',
    name: '售后管理',
    icon: "icon-shezhi",
    meta: { title: "售后管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/after/afterProvidedOrder",
        name: "供货售后",
        icon: "icon-wj-shd",
        meta: { title: "供货售后", requireAuth: true },
        component: () => import('@/views/after/afterProvidedOrder.vue')
      },
      {
        path: "/after/afterStoreOrder",
        name: "门店售后",
        icon: "icon-wj-shd",
        meta: { title: "门店售后", requireAuth: true },
        component: () => import('@/views/after/afterStoreOrder.vue')
      },
      {
        path: "/after/details/providedAfterDetails",
        name: "供货售后详情",
        icon: "icon-shouye",
        meta: { title: "供货售后订单详情", requireAuth: true },
        component: () => import('@/views/after/details/providedAfterDetails.vue')
      },
      {
        path: "/after/details/storeAfterDetails",
        name: "门店售后详情",
        icon: "icon-shouye",
        meta: { title: "门店售后订单详情", requireAuth: true },
        component: () => import('@/views/after/details/storeAfterDetails.vue')
      }
    ]
  },
  {
    path: '/renovation',
    name: '门店装修',
    icon: "icon-shezhi",
    meta: { title: "门店装修", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/renovation/startPage",
        name: "启动页",
        icon: "icon-zhuti_o",
        meta: { title: "启动页", requireAuth: true },
        component: () => import('@/views/renovation/startPage/startPage.vue')
      },
      {
        path: "/renovation/index",
        name: "我的店铺",
        icon: "icon-zhuti_o",
        meta: { title: "我的店铺", requireAuth: true },
        component: () => import('@/views/renovation/index.vue')
      },
      {
        path: "/renovation/navtion",
        name: "导航",
        icon: "icon-zhuti_o",
        meta: { title: "导航", requireAuth: true },
        component: () => import('@/views/renovation/navtion/navtion.vue')
      },
      {
        path: "/renovation/makePage",
        name: "装修",
        icon: "icon-zhuti_o",
        meta: { title: "装修", requireAuth: true },
        component: () => import('@/views/renovation/makePage/makePage.vue')
      },
      {
        path: "/renovation/theme",
        name: "主题风格",
        icon: "icon-zhuti_o",
        meta: { title: "主题风格", requireAuth: true },
        component: () => import('@/views/renovation/theme/theme.vue')
      }
    ]
  },
  {
    path: '/replenish',
    name: '补货通知',
    icon: "icon-shouye",
    meta: { title: "补货通知", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/replenish",
        // name: "补货通知",
        icon: "icon-shouye",
        meta: { title: "补货通知", requireAuth: true },
        component: () => import('@/views/replenish/index.vue')
      }
    ]
  },
  {
    path: '/special',
    name: '特卖会管理',
    icon: "icon-shouye",
    meta: { title: "特卖会管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/special/index",
        name: "活动列表",
        icon: "icon-shouye",
        meta: { title: "活动列表", requireAuth: true },
        component: () => import('@/views/special/index.vue')
      },
      {
        path: "/special/orderList",
        name: "订单管理",
        icon: "icon-shouye",
        meta: { title: "订单管理", requireAuth: true },
        component: () => import('@/views/special/orderList.vue')
      },
      {
        path: "/special/specialDetails",
        name: "活动详情",
        icon: "icon-shouye",
        meta: { title: "活动详情", requireAuth: true },
        component: () => import('@/views/special/details/specialDetails.vue')
      },
      {
        path: "/special/storeOrderDetails",
        name: "订单管理",
        icon: "icon-shouye",
        meta: { title: "订单管理", requireAuth: true },
        component: () => import('@/views/special/details/storeOrderDetails.vue')
      }
    ]
  },
  {
    path: '/address',
    name: '物流管理',
    icon: "icon-shezhi",
    meta: { title: "物流管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/address/addressList",
        name: "地址设置",
        icon: "icon-zhuti_o",
        meta: { title: "地址设置", requireAuth: true },
        component: () => import('@/views/address/addressList.vue')
      },
      {
        path: "/address/details/addressDetails",
        name: "地址设置详情",
        icon: "icon-zhuti_o",
        meta: { title: "地址设置详情", requireAuth: true },
        component: () => import('@/views/address/details/addressDetails.vue')
      },
      {
        path: "/address/freightTemplate",
        name: "运费模版",
        icon: "icon-zhuti_o",
        meta: { title: "运费模版", requireAuth: true },
        component: () => import('@/views/address/freightTemplate.vue')
      },
      {
        path: "/address/details/freightDetails",
        name: "运费模板详情",
        icon: "icon-zhuti_o",
        meta: { title: "运费模板详情", requireAuth: true },
        component: () => import('@/views/address/details/freightDetails.vue')
      },
    ]
  },
  {
    path: '/finance',
    name: '财务管理',
    icon: "icon-shezhi",
    meta: { title: "财务管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/finance/paymentDetail",
        name: "货款明细", 
        icon: "icon-changyongxinxi",
        meta: { title: "货款明细", requireAuth: true },
        component: () => import('@/views/finance/paymentDetail.vue')
      },
      {
        path: "/finance/paymentStatement",
        name: "货款明细对账单", 
        icon: "icon-changyongxinxi",
        meta: { title: "货款明细对账单", requireAuth: true },
        component: () => import('@/views/finance/paymentStatement.vue')
      },
      {
        path: "/finance/paymentManagement",
        name: "货款管理", 
        icon: "icon-changyongxinxi",
        meta: { title: "货款管理", requireAuth: true },
        component: () => import('@/views/finance/paymentManagement.vue')
      },
      {
        path: "/finance/paymentDistributionList",
        name: "分销列表", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销列表", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionList.vue')
      },
      {
        path: "/finance/paymentDistributionDetails",
        name: "分销款明细", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销款明细", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionDetails.vue')
      },
      {
        path: "/finance/paymentDistributionStatement",
        name: "分销款明细对账单", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销款明细对账单", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionStatement.vue')
      },
      {
        path: "/finance/paymentDistributionManagement",
        name: "分销款管理", 
        icon: "icon-changyongxinxi",
        meta: { title: "分销款管理", requireAuth: true },
        component: () => import('@/views/finance/paymentDistributionManagement.vue')
      }
    ]
  },
  {
    path: '/marketing',
    name: '营销管理',
    icon: "icon-shouye",
    meta: { title: "营销管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/marketing/modelShop",
        name: "样板店",
        icon: "icon-shouye",
        meta: { title: "样板店", requireAuth: true },
        component: () => import('@/views/marketing/modelShop.vue')
      },
      {
        path: "/marketing/usageRecord",
        name: "使用记录",
        icon: "icon-shouye",
        meta: { title: "使用记录", requireAuth: true },
        component: () => import('@/views/marketing/usageRecord.vue')
      }
    ]
  },
  {
    path: '/setup',
    name: '设置',
    icon: "icon-shezhi",
    meta: { title: "设置", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/setup/account",
        name: "提现账号", 
        icon: "icon-changyongxinxi",
        meta: { title: "提现账号", requireAuth: true },
        component: () => import('@/views/setup/information/account.vue')
      },
      {
        path: "/setup/makeSet",
        name: "预约设置", 
        icon: "icon-changyongxinxi",
        meta: { title: "预约设置", requireAuth: true },
        component: () => import('@/views/setup/information/makeSet.vue')
      },
      {
        path: "/setup/shopGoodsSet",
        name: "铺货设置", 
        icon: "icon-changyongxinxi",
        meta: { title: "铺货设置", requireAuth: true },
        component: () => import('@/views/setup/information/shopGoodsSet.vue')
      }
    ]
  },
  {
    path: '/pluginUnit',
    name: '插件',
    icon: "icon-shezhi",
    meta: { title: "插件", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/pluginUnit",
        // name: "插件",
        icon: "icon-shouye",
        meta: { title: "插件", requireAuth: true },
        component: () => import('@/views/pluginUnit/index.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/404.vue')
  }
]

//添加以下代码--解决路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title;
  document.title = localStorage.getItem('store_logo_title') || '门店管理系统';
  let hasToken = localStorage.getItem('hasToken');
  if(hasToken){
    next();
  }else{
    if(to.path == '/login'){
      return next();
    }else{
      next({path:'/'});
    }
  }
})

export default router
