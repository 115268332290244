<template>
    <div class="head">
        <!-- <div class="collapse" @click="collapse">
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
        </div> -->
        <!-- 折叠 需要打开左侧导航栏图标 -->

        <div class="name">
            <img src="../assets/store_logo.png" alt="">
            <span>{{ shop_name }}</span>
        </div>
        <div class="breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item  v-for="(item,index) in breadlitst" :key="index">{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-dropdown>
            <div class="user">
                <div class="sub-title">{{ username || '管理员' }}</div>
                <div class="block">
                    <!-- <el-avatar size="medium" src="../../assets/head.png"></el-avatar> -->
                    <img src="../assets/head.png" alt="">
                </div>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="homes"><i class="el-icon-s-home"></i>首页</el-dropdown-item>
                <!-- <el-dropdown-item @click.native="personal"><i class="el-icon-user"></i>个人中心</el-dropdown-item> -->
                <el-dropdown-item @click.native="editpassword"><i class="el-icon-edit"></i>修改密码</el-dropdown-item>
                <el-dropdown-item divided @click.native="exits"><i class="el-icon-circle-close"></i>退出</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <el-drawer
            title="个人中心"
            :visible.sync="drawer"
            direction="rtl"
            :before-close="handleClose"
            size="480px"
        >
            
            <div class="person">

                <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :hide-required-asterisk="true">
                    <el-form-item label="用户头像 :">
                        <!-- <el-image 
                            style="width: 100px; height: 100px"
                            :src="url" 
                            :preview-src-list="srcList">
                        </el-image> -->
                        <el-avatar shape="square" :size="100" fit="fit" :src="url"></el-avatar>
                    </el-form-item>
                    <el-form-item label="用户姓名 :">
                        <el-input v-model="formLabelAlign.name"></el-input>
                    </el-form-item>
                    <el-form-item label="性别 :">
                        <el-select v-model="formLabelAlign.sex" placeholder="请选择性别" style="width:100%;">
                            <el-option label="男" value="nan"></el-option>
                            <el-option label="女" value="nv"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系方式 :">
                        <el-input v-model="formLabelAlign.tel"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱 :">
                        <el-input v-model="formLabelAlign.mailbox"></el-input>
                    </el-form-item>
                    <el-form-item label="居住地址 :">
                        <el-input v-model="formLabelAlign.address"></el-input>
                    </el-form-item>
                </el-form>

            </div>
            
                <div class="bottons">
                    <el-row type="flex" justify="end">
                        <el-col :span="5"><el-button @click="drawer = false">取消</el-button></el-col>
                        <el-col :span="5"><el-button type="primary" @click="drawer = false">确认</el-button></el-col>
                    </el-row>
                </div>
            
        </el-drawer>

        <!-- 修改密码 -->
        <el-dialog title="管理员设置" :visible.sync="isAuth" width="30%">
            <div style="margin: 0 50px 0 0">
                <el-form :model="passwordForm" ref="passwordForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="原密码：" prop="old_password" :rules="[{ required: true, message: '密码不能为空'}]">
                        <el-input v-model="passwordForm.old_password" show-password autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="修改密码：" prop="new_password" :rules="[{ required: true, message: '新密码不能为空'}]">
                        <el-input v-model="passwordForm.new_password" show-password autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码：" prop="new_password_tow" :rules="[{ required: true, message: '新密码不能为空'}]">
                        <el-input v-model="passwordForm.new_password_tow" show-password autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isAuth = false" size="small">取 消</el-button>
                <el-button type="primary" size="small" @click="adminPassword('passwordForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改密码 -->
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {saveShopPassword} from '@/api/permission';
import CryptoJS from 'crypto-js';
export default {
    name: "Header",
    data() {
        return {
            shop_name:localStorage.getItem('shop_name'),
            drawer:false,   //个人中心
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            labelPosition: 'right',
            formLabelAlign: {
                name: '小天',
                sex: '男',
                tel: '17682310521',
                mailbox:'728682378@qq.com',
                address:'杭州市拱墅区拱宸桥东'
            },
            isAuth:false,
            passwordForm:{
                old_password:'',    //密码
                new_password:'', //新密码
                new_password_tow:'',    //新密码
            },
            breadlitst:[],  //面包屑数据
            username:localStorage.getItem('username'),//用户名
        }
    },
    created() {
        // console.log(111);
    },
    computed:{
        isCollapse(){  //展开列表
            return this.$store.state.isCollapse
        }
    },
    watch:{
        $route:{
            handler(route){
                let breadlitst = [];
                let List = route.matched;
                List.forEach(item => {
                    breadlitst.push(item.meta.title)
                });
                const uniqueArr = Array.from(new Set(breadlitst));
                this.breadlitst = uniqueArr;
            },immediate:true
        }
    },
    mounted() { },
    methods: {
        collapse(){
            this.$store.commit('collapse',!(this.isCollapse))
        },
        homes(){    //首页
            localStorage.removeItem('activeMenu');  //清楚左侧导航缓存
            localStorage.setItem('activeMenu','1'); //返回首页设置下表为1
            this.$store.commit('activeMenus','1');   //修改vuex里面的数据
            this.$router.push({path:'/home'});
        },
        personal(){ //个人中心
            this.drawer = true;
        },
        handleClose(){  //关闭个人弹窗
            this.drawer = false;
        },
        editpassword(){ //修改密码
            this.isAuth = true;
            this.passwordForm.old_password = '';
            this.passwordForm.new_password = '';
            this.passwordForm.new_password_tow = '';
        },
        adminPassword(formName){    //修改密码
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = _.cloneDeep(this.passwordForm);
                    if(data.new_password != data.new_password_tow){
                        this.$message({
                            type: 'error',
                            message: '请查看密码是否一致！'
                        });
                        return
                    }
                    data.old_password = CryptoJS.SHA256(data.old_password).toString();
                    data.new_password = CryptoJS.SHA256(data.new_password).toString();
                    delete data.new_password_tow;
                    saveShopPassword(data).then(res=>{
                        this.$message({
                            type: 'success',
                            message: '管理员密码修改成功!'
                        });
                        this.isAuth = false;
                        setTimeout(()=>{
                            this.$router.push({ path: '/login' });
                        }, 3000 )
                    }).catch(err=>{})
                } else {
                    return false;
                }
            });
        },
        exits(){    //退出
            this.$confirm('是否确认继续退出该程序?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    localStorage.removeItem('activeMenu');  //清楚左侧导航缓存
                    localStorage.removeItem('hasToken');
                    this.$router.push({ path: '/login' });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
            });
        }
    },
};
</script>
<style lang="less" scoped>
.head{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7f8fa;
    padding: 0 20px;
    .name{
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 20px;
            height: 18px;
            margin-right: 10px;
        }
        span{
            font-size: 18px;
            font-weight: 500;
            color: #639AFF;
        }
    }

    .person{
        height: 90%;
        margin: 0 30px;
    }
    /deep/.el-drawer__header{
        font-weight: bold;
        font-size: 18px;
        color: #333;
        border-bottom: 1px solid #f1f1f1;
    }
    .bottons{
        border-top: 1px solid #f1f1f1;
    }
    .breadcrumb{
        margin-left: 50px;
        /deep/.el-breadcrumb__item{
            font-size: 18x;
        }
    }
    .collapse{
        flex: 1;
        .el-icon-s-fold,.el-icon-s-unfold{
            font-size: 24px;
            cursor: pointer;
        }
        .el-icon-s-fold:hover,.el-icon-s-unfold:hover{
            color: #1989fa;
        }
    }
    /deep/.el-dropdown{
        margin-left: auto;
    }
    .user{
        text-align: right;
        min-width: 300px;
        margin-left: auto;
        .sub-title{
            display: inline-block;
            line-height: 60px;
        }
        .block{
            float: right;
            margin: 12px 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                width: 36px;
                height: 36px;
            }
        }
    }
    .user:hover{
        cursor: pointer;
    }
}
</style>