import { request } from '@/api/request';

//商户登录
export const shopLogin = data => {
	return request('post','/api/Login/ShopAdmin/login',data);
}

//门店用户列表
export const shopUserList = data => {
	return request('get','/api/Account/ShopUser/list',data);
}

//门店用户列表
export const shopUserDetail = data => {
	return request('get','/api/Account/ShopUser/detail',data);
}

//门店用户消费信息
export const consumptionInfo = data => {
	return request('get','/api/Account/ShopUser/consumptionInfo',data);
}

//概览
export const dataindex = data => {
	return request('get','/api/data/index',data);
}

//补货通知
export const replenishmentList = data => {
	return request('get','/api/goods/replenishmentList',data);
}

//获取系统配置
export const getConfig = data => {
	return request('get','/api/Settings/system/getConfig',data);
}