import Vue from 'vue';
import store from '@/store';
const arr = store.state.permissions;
const permission = {
	// 全局注册自定义指令
    install (Vue) {
        // 使用 inserted 函数，在被绑定元素插入父节点时检测该元素是否有权限
        Vue.directive('has', {
            inserted (el, binding, vnode) {
            /*el: 指令所绑定的元素，可以用来直接操作DOM。
                binding: 一个对象，包含指令的很多信息。
                vnode: Vue编译生成的虚拟节点。*/
                const value = binding.value ? binding.value.trim() : '';//传入参数
                if(arr.includes(value)){
                    // el.parentNode && el.parentNode.removeChild(el)
                }
                // const path = window.location.hash.slice(1).split('?')[0]
                // const { value } = binding
                // 		// 自定义指令的执行函数，取到登录缓存的按钮权限数组
                // const power = JSON.parse(arr)
                // console.log("power",power)
                // const operate = power[path] || {}
                // if (!operate[value]) {
                // 			// 判断是否有权限，如果没有权限，则删除该节点
                //   el.parentNode && el.parentNode.removeChild(el)
                // }
            }
        })
    }
}
export default permission
