import axios from 'axios';
import CryptoJS from 'crypto-js'
import {Message} from 'element-ui'
import { baseURL, strKey, iv } from "@/config.js"
import Tools from '@/utils/tools'
import router from '../router'

// 加密函数
function encrypt(str) {
    var key1 = CryptoJS.enc.Utf8.parse(strKey);
    var iv1 = CryptoJS.enc.Utf8.parse(iv);
    var encrypted = CryptoJS.AES.encrypt(str, key1, {
        iv: iv1,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}

// 对象按照key排序
function ksort(o) {
    let str = '';
    let keys = Object.keys(o);
    keys.sort();
    keys.forEach((key) => {
        str += `${key}=${o[key]}&`;
    })
    // 加密
    return CryptoJS.MD5(str.substr(0, str.length - 1)).toString();
}


axios.defaults.baseURL = process.env.NODE_ENV === "production" ? baseURL : baseURL;
// axios.defaults.baseURL = process.env.NODE_ENV === "production" ? baseURL : '';


//设置超时
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
    config => {
        let dataObj = config.method === "get" ? config.params : config.isUpload ? {} : JSON.parse(config.data);// 请求体
        if (config.isUpload) {//文件格式特殊处理
            config.data.forEach((value, key) => {
                if (key != 'file') { dataObj[key] = value }
            });
        }
        let timestampV = new Date().getTime(),
            verifyV = Math.floor((Math.random() * (20000 - 100) + 100)),
            accountV = localStorage.getItem('attach') || 0,
            aesObj = {
                'timestamp': timestampV,
                'verify': verifyV,
                'attach': accountV,
                'sign': '',
            };

        aesObj.sign = ksort({
            'timestamp': timestampV,
            'verify': verifyV,
            ...dataObj,
        });
        // if (process.env.NODE_ENV === 'production') {
        //     config.url = config.url.split('/api').join('');
        // }
        //判断文件下载
        let downfile = config.url.substring(config.url.lastIndexOf("/") + 1);
        if (downfile == 'orderConsignment') {
            config.responseType = 'blob'
        }
        // 配置token
        config.headers.appid = iv;
        config.headers.token = encrypt(JSON.stringify(aesObj));
        localStorage.setItem('token', encrypt(JSON.stringify(aesObj)));
        return config;
    },
    error => {
        Message({
            message: '请求超时!',
            type: 'warning',
        })
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        Message.error(JSON.stringify(error.message))
    }
);
/*
  @参数一 请求方式不限制大小写  必填
  @参数二 请求接口             必填选填     
  @参数三 请求参数             选填      （Object） 默认{}
  @参数四 是否是上传文件        选填     （Boolean） 默认false
*/
let request = (type, url, data = {}, option = false) => {
    return new Promise((resolve, reject) => {
        let dataC = option ? data : Tools.filterParams(data);
        axios.defaults.headers["Content-Type"] = option ? 'application/x-www-form-urlencoded' : 'application/json;';
        let requestObj = {
            method: type.toLowerCase(),
            url,
            isUpload: option
        };
        switch (type.toLowerCase()) {
            case 'post':
                requestObj.data = option ? dataC : JSON.stringify(dataC)
                break;
            case 'get':
                requestObj.params = dataC
                break;
        }
        if (Tools.LastStr(url) == 'modifyParent') {
            requestObj.timeout = 50 * 60 * 1000;
        }
        if (option) {
            requestObj.timeout = 2000 * 1000;
        }
        axios(requestObj).then(res => {
            if (res.data.error == 0) {
                resolve(res.data)
            } else if (res.data.error == 104|| res.data.error == 701) {
                Message.error(res.data.message)
                localStorage.clear();
                setTimeout(() => {
                    router.push({ path: '/login' })
                }, 3000);
                reject(res.data)
            } else if (res.headers["content-type"] == "application/octet-stream") {//判断返回文件流
                resolve({ type: 'fill', data: res.data })
            } else {
                Message.error(res.data.message)
                reject(res)
            }
        })
        .catch(err => {
            Message.error(err.message)
            reject("err",err)
        });
    })
}

export { request };

