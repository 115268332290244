/**
 * 过滤空参数
 * @return {string} ojgdvbvaua40
 */
function filterParams(params) {
    for (const i in params) {
      if (params[i] === null || params[i] === undefined || params[i] === '' || params[i].length == 0) {
        delete params[i];
      }
    }
    return params;
}

//获取最后斜杠后的字符串
function LastStr(str) {
    let index = str.lastIndexOf("\/");
    str = str.substring(index + 1, str.length);
    return str;
}

export default {
    filterParams,
    LastStr
}