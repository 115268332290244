import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Fragment from 'vue-fragment';
import permission from './directives/permission';
import './assets/font/iconfont.css';
import Tools from './utils/tools';
import './assets/css.less';
import Cookies from 'vue-cookies';
Vue.use(Cookies);
Vue.use(permission);
Vue.use(Fragment.Plugin);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.Tools = Tools;

new Vue({
  router,
  store,
  el:'#app',
  render: h => h(App)
}).$mount('#app')
